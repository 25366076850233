/***************************************************************************************************
** useAsyncRequest
***************************************************************************************************/

import { useMemo, useCallback } from 'react';
import { Router, buildUrl } from '@utils';

const defaultProps = {
  method: 'PATCH',
  params: {},
  onSuccess: (_response) => {},
  onFailure: (response) => {
    throw new Error(response.error || response.status);
  },
  contentType: 'application/json',
};

export default function useAsyncRequest(props) {
  const {
    url,
    path,
    params,

    method,
    onSuccess,
    onFailure,
    contentType,
  } = { ...defaultProps, ...props };

  const requestUrl = useMemo(() => {
    const baseUrl = url || (path && Router.buildUrlFor(`/api/${path}`));

    return baseUrl && buildUrl(baseUrl, params);
  }, [
    url,
    path,
    params,
  ]);

  /**************************************************************************************************/

  const requestHeaders = useMemo(() => {
    const headers = new Headers({
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store',
      'Authorization-Token': window.appContext.authorizationToken,
    });

    if (contentType === 'application/json') {
      headers.set('Content-Type', contentType);
    }

    return headers;
  }, [contentType]);

  const __sendAsyncRequest = useCallback(async (url, options = {}) => {
    if (!url) throw new Error();

    const requestOptions = {
      method: options.method,
      headers: options.headers,
    };

    if (options.data) {
      if (contentType === 'application/json') {
        requestOptions.body = JSON.stringify(options.data);
      } else {
        requestOptions.body = options.data;
      }
    }

    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();

    if (response.ok) {
      onSuccess(responseBody);

      return responseBody;
    } else {
      onFailure(responseBody);
      throw new Error(responseBody.error || responseBody.status);
    }
  }, [
    contentType,
    onSuccess,
    onFailure,
  ]);

  /***************************************************************************************************
  ** sendAsyncRequest
  ***************************************************************************************************/

  // const sendAsyncRequest = React.useCallback(async (url, options = {}) => {
  //   const requestOptions = {
  //     method: method,
  //     headers: requestHeaders,
  //     ...options,
  //   };

  //   return await __sendAsyncRequest(url, requestOptions);
  // }, [
  //   __sendAsyncRequest,
  //   requestHeaders,
  //   method,
  // ]);

  /***************************************************************************************************
  ** sendAsyncGetRequest
  ***************************************************************************************************/

  const sendAsyncGetRequest = useCallback(async (additionalParams = {}) => {
    const requestOptions = {
      method: 'GET',
      headers: requestHeaders,
    };

    const __requestParams = { ...params, ...additionalParams };
    const __requestUrl = buildUrl(requestUrl, __requestParams);

    return await __sendAsyncRequest(__requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    requestUrl,
    params,
  ]);

  /***************************************************************************************************
  ** sendAsyncPatchRequest
  ***************************************************************************************************/

  const sendAsyncPatchRequest = useCallback(async (data) => {
    const requestOptions = {
      method: 'PATCH',
      headers: requestHeaders,
      data: data,
    };

    return await __sendAsyncRequest(requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    requestUrl,
  ]);

  /***************************************************************************************************
  ** sendAsyncPostRequest
  ***************************************************************************************************/

  const sendAsyncPostRequest = useCallback(async (data) => {
    const requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      data: data,
    };

    return await __sendAsyncRequest(requestUrl, requestOptions);
  }, [
    __sendAsyncRequest,
    requestHeaders,
    requestUrl,
  ]);

  /***************************************************************************************************
  ** sendAsyncGetRequest
  ***************************************************************************************************/

  const sendAsyncDeleteRequest = useCallback(async (url = requestUrl) => {
    const requestOptions = {
      method: 'DELETE',
      headers: requestHeaders,
    };

    return await __sendAsyncRequest(url, requestOptions);
  }, [
    __sendAsyncRequest,
    requestUrl,
    requestHeaders,
  ]);

  /**************************************************************************************************/

  switch (method.toUpperCase()) {
  case 'GET':
    return sendAsyncGetRequest;
  case 'POST':
    return sendAsyncPostRequest;
  case 'PATCH':
    return sendAsyncPatchRequest;
  case 'DELETE':
    return sendAsyncDeleteRequest;
    // default:
    //   return sendAsyncRequest;
  }
}
