import { useMeasure } from 'react-use';

import cn from '@utils/cn';
import { getIconProps } from '@utils';
import Icon from '@elements/Icon';

const defaultProps = {
  className: '',
  value: 'Click',
  type: 'button',
  ariaLabel: 'button',

  isDisabled: false,
  isVisible: true,
  isLoading: false,

  iconSize: '1x',
  onClick: (event) => (event.preventDefault()),
};

const ButtonIcon = (props) => {
  const {
    isVisible,
    ...iconProps
  } = props;

  const styles = {
    visibility: isVisible ? 'visible' : 'hidden',
  };

  if (!iconProps) return null;

  return (
    <span className="icon" style={styles}>
      <Icon {...iconProps} />
    </span>
  );
};

const ButtonText = (props) => {
  const {
    value,
    isVisible,
  } = props;

  const styles = {
    visibility: isVisible ? 'visible' : 'hidden',
  };

  if (!value) return null;

  return (
    <span style={styles}>
      {value}
    </span>
  );
};

export default function Button(props) {
  const {
    id,
    className,
    value,
    type,
    tooltip,
    ariaLabel,

    iconName,
    iconProps,
    iconSize,
    rightIconName,
    fallbackIconName,

    disableWith,
    minWidth,
  } = { ...defaultProps, ...props };

  const isDisabled = props.isDisabled ?? defaultProps.isDisabled;
  const isVisible = props.isVisible ?? defaultProps.isVisible;
  const isLoading = props.isLoading ?? defaultProps.isLoading;

  const onClick = props.onClick ?? defaultProps.onClick;

  const isIconOnly = props.isIconOnly ?? defaultProps.isIconOnly;

  /**************************************************************************************************/

  const leftIconProps = getIconProps(iconName, {
    fixedWidth: true,
    size: iconSize,
    ...iconProps,
  });

  const rightIconProps = getIconProps(rightIconName, {
    fixedWidth: true,
    size: iconSize,
  });

  const fallbackIconProps = getIconProps(fallbackIconName, {
    fixedWidth: true,
    size: iconSize,
  });

  /***************************************************************************************************
  ** TODO: WIP
  ***************************************************************************************************/

  const [ref, { width }] = useMeasure();

  const displayFallbackIcon = Boolean(fallbackIconName) && Boolean(minWidth) && (width < minWidth);

  /**************************************************************************************************/

  const classNames = cn(['kinn-button', className], {
    'is-loading': isLoading,
  });

  if (!isVisible) return null;

  return (
    <button
      id={id}
      ref={ref}
      className={classNames}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
      data-disable-with={disableWith}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
    >
      {iconName && (
        <ButtonIcon
          isVisible={!isLoading}
          {...leftIconProps}
        />
      )}

      {!isIconOnly && !displayFallbackIcon && (
        <ButtonText
          value={value}
          isVisible={!isLoading}
        />
      )}

      {!isIconOnly && displayFallbackIcon && (
        <ButtonIcon {...fallbackIconProps} />
      )}

      {rightIconName && (
        <ButtonIcon
          isVisible={!isLoading}
          {...rightIconProps}
        />
      )}
    </button>
  );
}
