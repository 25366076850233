import cn from '@utils/cn';
import Icon from '@elements/Icon';

export default function ErrorFallbackPage(props) {
  const {
    error,
    _resetErrorBoundary,
  } = props;

  const classNames = cn(['container', 'is-fullhd', 'tw-min-h-screen', 'tw-pb-2']);

  return (
    <div className={classNames}>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-border tw-border-zinc-300 tw-bg-white tw-p-5 tw-text-black">
        <div className="tw-grid tw-grid-cols-12">
          <div className="tw-col-span-12">
            <br />
            <div className="tw-text-center tw-text-5xl">
              <Icon iconName="triangle-exclamation" />
            </div>
            <div className="tw-text-center tw-text-[2rem]">
              Something went wrong!
            </div>
            <hr />
            {error?.message && (
              <pre>
                {error.message}
              </pre>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}
