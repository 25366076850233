import cn from '@utils/cn';

export default function NavbarBurger(props) {
  const { onClick, isActive } = props;

  const classNames = cn(['navbar-burger'], {
    'is-active': isActive,
  });

  return (
    <a
      className={classNames}
      role="button"
      onClick={onClick}
      aria-label="menu"
      aria-expanded="false"
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  );
}
