/***************************************************************************************************
** useFetch
***************************************************************************************************/

import { useMemo, useState, useCallback } from 'react';
import { toastify } from '@vendors';
import useAsyncRequest from '@hooks/useAsyncRequest';

const defaultProps = {
  onFetchSuccess: (() => {}),
  onFetchFailure: (() => toastify.error('Something went wrong with loading this page!')),
};

export default function useFetch(props) {
  const {
    url,
    path,
    params,

    onFetchSuccess,
    onFetchFailure,
  } = { ...defaultProps, ...props };

  const initialData = useMemo(() => (props.initialData || []), [props.initialData]);
  const [data, setData] = useState(initialData);
  const appendData = (v) => setData((values) => values.concat(v));

  /**************************************************************************************************/

  const sendAsyncGetRequest = useAsyncRequest({
    url: url,
    path: path,
    params: params,
    method: 'GET',
  });

  /**************************************************************************************************/

  const [fetchState, setFetchState] = useState('notloaded');
  const [fetchMeta, setFetchMeta] = useState({});

  const handleFetchSuccess = useCallback(() => {
    setFetchState('loaded');
    onFetchSuccess();
  }, [onFetchSuccess]);

  const handleFetchFailure = useCallback((error) => {
    console.error('Error', error);

    setFetchState('error');
    onFetchFailure();
  }, [onFetchFailure]);

  const fetchData = useCallback(async (additionalParams = {}, mode = 'set') => {
    setFetchState('loading');

    const _response = await sendAsyncGetRequest(additionalParams).then((response) => {
      setFetchMeta(response.meta);
      const records = response.records;

      if (mode === 'append') {
        appendData(records);
      } else {
        setData(records);
      }

      handleFetchSuccess();

      return records;
    }).catch((_error) => {
      handleFetchFailure();

      return initialData;
    });
  }, [
    sendAsyncGetRequest,
    initialData,
    handleFetchSuccess,
    handleFetchFailure,
  ]);

  /**************************************************************************************************/

  return [
    data,
    fetchData,
    fetchState,
    fetchMeta,
  ];
}
