// TODO: Refactor to use getIconProps

import cn from '@utils/cn';
import { getIconProps } from '@utils';
import Icon from '@elements/Icon';

import './Avatar.css';

const defaultProps = {
  size: '2rem',
  placeholderIcon: 'circle-user',
};

export default function Avatar(props) {
  const {
    url,
  } = { ...defaultProps, ...props };

  const onClick = props.onClick || (() => {});
  const size = props.size || defaultProps.size;

  const hasValidUrl = Boolean(url) && url !== 'null';

  const placeholderIconProps = getIconProps(props.placeholderIcon || defaultProps.placeholderIcon, {
    fixedWidth: false,
    color: '#AAAAAA',
    transform: props.placeholderIcon ? 'shrink-5' : '',
    mask: props.placeholderIcon ? 'circle' : '',
    style: { fontSize: size },
  });

  /**************************************************************************************************/

  const classNames = cn('avatar', {
    'avatar--clickable': props.onClick,
    ...props.className,
  });

  return (
    <div className={classNames} onClick={onClick}>
      {hasValidUrl ? (
        <div
          className={props.className}
          id={props.id}
          style={{
            backgroundColor: '#AAAAAA',
            backgroundImage: `url('${url}')`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
            borderRadius: '50%',
            height: size,
            minHeight: size,
            minWidth: size,
            width: size,
            ...props.style,
          }}
        />
      ) : (
        <Icon
          className={props.className}
          {...placeholderIconProps}
        />
      )}
    </div>
  );
}
