/***************************************************************************************************
** KinndomContext
***************************************************************************************************/

import { use, createContext, useEffect } from 'react';

import { useFetch } from '@hooks';

export const KinndomContext = createContext();

export const KinndomContextProvider = (props) => {
  const [kinndom, fetchKinndom] = useFetch({
    path: `kinndoms/${window.appContext.kinndomKey}`,
  });

  useEffect(() => {
    if (window.appContext.kinndomKey) {
      fetchKinndom();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.appContext.kinndomKey]);

  return (
    <KinndomContext value={kinndom}>
      {props.children}
    </KinndomContext>
  );
};

export const useKinndomContext = () => {
  const context = use(KinndomContext);

  if (!context) {
    throw new Error('KinndomContext must be used within a KinndomContextProvider');
  }

  return context;
};
