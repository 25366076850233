import { getIconProps } from '@utils';
import Icon from '@elements/Icon';
import Avatar from '@elements/Avatar';

const NavbarMenuLabelAvatar = (props) => {
  const { imageUrl } = props;

  if (!imageUrl) return null;

  return (
    <Avatar
      className="tw-mr-2 tw-hidden md:tw-block"
      size="1.5rem"
      url={imageUrl}
    />
  );
};

export default function NavbarMenuLabel(props) {
  const {
    label,
    onClick,
  } = props;

  const {
    text,
    imageUrl,
  } = label;

  const iconProps = getIconProps(label.icon);

  return (
    <div
      className="navbar-link"
      onClick={onClick}
      aria-haspopup="true"
      aria-controls="dropdown-menu"
    >
      {iconProps && (
        <Icon {...iconProps} />
      )}
      <NavbarMenuLabelAvatar
        imageUrl={imageUrl}
      />
      {text}
    </div>
  );
}
