import cn from '@utils/cn';
import { Router, getIconProps } from '@utils';
import { useSessionContext } from '@contexts/SessionContext';
import { useFlags } from '@hooks';
import Icon from '@elements/Icon';
import Flags from '@elements/Flags';

const FLAG_DEFINITIONS = [
  {
    key: 'isExternalURL',
    label: 'External Link',
    icon: 'up-right-from-square',
  },
];

const defaultProps = {
  isEnabled: true,
  isPreview: false,
  isAdminOnly: false,
  openInNewWindow: false,
};

export default function NavbarDropdownLinkItem(props) {
  const {
    pageKey,
    label,
    tooltip,

    isEnabled,
    isPreview,
    isAdminOnly,
    openInNewWindow,
  } = { ...defaultProps, ...props };

  const {
    text,
  } = label;

  const iconProps = getIconProps(label.icon, {
    fixedWidth: true,
  });

  const url = (props.path && Router.buildUrlFor(props.path)) || props.url;

  const isExternalURL = props.url && props.url.startsWith('http');

  const flags = useFlags(FLAG_DEFINITIONS, { isPreview, isExternalURL }, props.flags);

  /**************************************************************************************************/

  const { isCurrentPage } = useSessionContext();
  const isActive = isCurrentPage(pageKey);

  /**************************************************************************************************/

  const px = {};

  if (isEnabled) {
    px.href = url;
  }

  if (openInNewWindow) {
    px.target = '_blank';
  }

  const classNames = cn(['navbar-dropdown-item', 'navbar-item', 'tw-text-base', 'tw-select-none'], {
    'navbar-item--disabled': !isEnabled,
    'is-active': isActive,
  });

  /**************************************************************************************************/

  return (
    <a
      className={classNames}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
      {...px}
    >
      {iconProps && (
        <span className="icon">
          <Icon {...iconProps} />
        </span>
      )}

      {text}
      {isAdminOnly && (<span className="tw-font-light">*</span>)}

      <Flags
        flags={flags}
        flagClassNames="tw-opacity-75 tw-ml-2"
        displayTooltip={false}
      />
    </a>
  );
}
