import { useMemo } from 'react';
import { useToggle } from 'react-use';

import cn from '@utils/cn';
import { interpolate } from '@utils';
import { useKinndomContext } from '@contexts/KinndomContext';

import NavbarLinkItem from '@components/navbar/NavbarLinkItem';
import NavbarMenu from '@components/navbar/navbar_menu/NavbarMenu';
import NavbarBurger from '@components/navbar/NavbarBurger';

import './Navbar.scss';

const NAVBAR_EMOJI = process.env.NODE_ENV === 'production' ? '💠' : '🌿';

// TODO: Extract out into separate component
const NavbarQuickLinks = (props) => {
  const {
    currentUserId,
    unreadNotificationsCount,
  } = props;

  const hasUnreadNotifications = unreadNotificationsCount > 0;

  const items = [
    {
      label: {
        icon: {
          name: hasUnreadNotifications ? 'bell-exclamation' : 'bell',
          color: hasUnreadNotifications ? '#FFCB00' : '#FFFFFF',
        },
        text: hasUnreadNotifications ? unreadNotificationsCount : null,
      },
      path: `/user-notifications?user_id=${currentUserId}`,
      tooltip: 'Notifications',
    },
  ];

  return items.map((item, index) => (
    <NavbarLinkItem
      key={index}
      label={item.label}
      url={item.url}
      path={item.path}
      openInNewWindow={item.openInNewWindow}
      tooltip={item.tooltip}
    />
  ));
};

// TODO: Extract out into separate component
const UserNavbarMenu = (props) => {
  const {
    userName,
    userAvatarUrl,
    latestChangelogVisited,
  } = props;

  const label = {
    text: userName || 'User',
    imageUrl: userAvatarUrl,
  };

  const flags = useMemo(() => {
    const flags = [];

    if (!latestChangelogVisited) {
      flags.push({
        icon: 'circle-small',
        color: '#CF2828',
      });
    }

    return flags;
  }, [latestChangelogVisited]);

  const items = [
    {
      type: 'link',
      label: {
        text: 'Profile',
        icon: 'user',
      },
      path: '/profile',
    },
    {
      type: 'link',
      label: {
        text: 'Log Out',
        icon: 'right-from-bracket',
      },
      path: '/logout',
    },
    {
      type: 'divider',
    },
    {
      type: 'link',
      label: {
        text: window.KINN_APP_VERSION,
      },
      path: '/changelog',
      flags: flags,
    },
  ];

  return (
    <NavbarMenu
      label={label}
      items={items}
      isAlignedRight={true}
    />
  );
};

export default function Navbar(_props) {
  const kinndom = useKinndomContext();

  const {
    kinndomKey,
    defaultWebsiteId,
    defaultWebsiteBaseUrl,
    isAuthenticated,

    currentUserId,
    currentUserName,
    isCurrentUserAdmin,
    currentUserHomepageOverride,
    currentUserAvatarUrl,
    currentUserLatestChangelogVisited,
    currentUserUnreadNotificationsCount,
  } = window.appContext;

  // TODO: Refactor
  const interpolationData = useMemo(() => ({
    kinndomKey: kinndomKey,
    defaultWebsiteId: defaultWebsiteId,
    defaultWebsiteBaseUrl: defaultWebsiteBaseUrl,

    kinndom: kinndom,
    config: kinndom.configuration,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [kinndom]);

  const navbarMenus = useMemo(() => {
    return window.NAVBAR_MENUS
      .filter((menu) => (
        !menu.isHidden
        && (!menu.isAdminOnly || isCurrentUserAdmin)
      )).map((menu) => interpolate(menu, interpolationData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpolationData]);

  const DEFAULT_HOMEPAGE_URL = '/';
  const homepageUrl = currentUserHomepageOverride || DEFAULT_HOMEPAGE_URL;

  /**************************************************************************************************/

  const [showNavbarMenu, toggleNavbarMenu] = useToggle(false);

  const navbarMenuClassNames = cn(['navbar-menu'], {
    'is-active': showNavbarMenu,
  });

  /**************************************************************************************************/

  if (!isAuthenticated) return null;

  return (
    <nav className="navbar is-fixed-top is-primary tw-top-0">
      {/* <div className="tw-container tw-mx-auto tw-max-w-screen-xl"> */}
      <div className="container is-fullhd">
        <div className="navbar-brand">
          <NavbarLinkItem
            label={{
              text: NAVBAR_EMOJI,
            }}
            url={homepageUrl}
          />
          <NavbarBurger
            onClick={toggleNavbarMenu}
            isActive={showNavbarMenu}
          />
        </div>
        <div className={navbarMenuClassNames}>
          <div className="navbar-start">
            {navbarMenus.map((menu, index) => {
              if (menu.type === 'link') {
                return (
                  <NavbarLinkItem
                    key={index}
                    label={menu.label}
                    url={menu.url}
                    path={menu.path}
                    openInNewWindow={menu.openInNewWindow}
                    tooltip={menu.tooltip}
                  />
                );
              } else {
                return (
                  <NavbarMenu
                    key={index}
                    label={menu.label}
                    items={menu.items}
                  />
                );
              }
            })}
          </div>
          <div className="navbar-end">
            <NavbarQuickLinks
              currentUserId={currentUserId}
              unreadNotificationsCount={currentUserUnreadNotificationsCount}
            />
            <UserNavbarMenu
              userName={currentUserName}
              userAvatarUrl={currentUserAvatarUrl}
              latestChangelogVisited={currentUserLatestChangelogVisited}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
