/***************************************************************************************************
** useOperation
***************************************************************************************************/

import { useCallback } from 'react';

import { Router, redirectTo } from '@utils';
import { toastify } from '@vendors';
import useAsyncRequest from '@hooks/useAsyncRequest';

const defaultProps = {
  onEnqueueSuccess: () => {},
  onEnqueueFailure: () => {},
  onEnqueueSuccessMessage: 'The operation has been queued for execution.',
  onEnqueueFailureMessage: 'Something with wrong when attempting to enqueue the operation.',
};

export default function useOperation(props) {
  const {
    key,
    args,
    enqueueDelay,
    onEnqueueSuccess,
    onEnqueueFailure,
    onEnqueueSuccessMessage,
    onEnqueueFailureMessage,
  } = { ...defaultProps, ...props };

  /**************************************************************************************************/

  const callback = props.callback || {};
  const callbackAction = callback.action || 'none';
  const callbackUrl = callback.url || (callback.path && Router.buildUrlFor(callback.path));

  const handleEnqueueOperationSuccess = useCallback((data) => {
    onEnqueueSuccess(data);

    if (callbackAction === 'reload') {
      toastify.onReload({
        toastId: key,
        type: 'info',
        value: onEnqueueSuccessMessage,
        // autoClose: false,
      });

      window.location.reload();
    } else if (callbackAction === 'redirect' && callbackUrl) {
      toastify.onReload({
        toastId: key,
        type: 'info',
        value: onEnqueueSuccessMessage,
        // autoClose: false,
      });

      redirectTo(callbackUrl);
    } else {
      toastify.notify({
        toastId: key,
        type: 'info',
        value: onEnqueueSuccessMessage,
        // autoClose: false,
      });
    }
  }, [key, callbackAction, callbackUrl, onEnqueueSuccess, onEnqueueSuccessMessage]);

  const handleEnqueueOperationFailure = useCallback((error) => {
    onEnqueueFailure(error);
    toastify.error(onEnqueueFailureMessage);
    console.error(error);
  }, [onEnqueueFailure, onEnqueueFailureMessage]);

  const sendEnqueueOperationRequest = useAsyncRequest({
    path: '/operations',
    method: 'POST',
    onSuccess: handleEnqueueOperationSuccess,
    onFailure: handleEnqueueOperationFailure,
  });

  const enqueueOperation = useCallback(() => {
    sendEnqueueOperationRequest({
      operation: {
        key: key,
        enqueue_delay: enqueueDelay,
        ...args,
      },
      current_user_id: window.appContext.currentUserId,
    });
  }, [key, enqueueDelay, args, sendEnqueueOperationRequest]);

  return enqueueOperation;
}
