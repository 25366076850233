import cn from '@utils/cn';
import LoaderCircle from '@elements/LoaderCircle';

export default function PageLoader() {
  const classNames = cn(['container', 'is-fullhd', 'tw-min-h-screen']);

  return (
    <div className={classNames}>
      <div className="tw-flex tw-justify-center tw-pt-12" style={{ marginTop: '5rem'}}>
        <LoaderCircle
          size="min(20vw, 20vh)"
          width="0.5rem"
          color="#AAAAAA"
        />
      </div>
    </div>
  );
}
