import cn from '@utils/cn';
import { Router, getIconProps } from '@utils';
import Icon from '@elements/Icon';

const defaultProps = {
  openInNewWindow: false,
};

export default function NavbarLinkItem(props) {
  const {
    label,
    tooltip,

    openInNewWindow,
  } = { ...defaultProps, ...props };

  const {
    text,
  } = label;

  const iconProps = getIconProps(label.icon, {
    fixedWidth: true,
  });

  const url = (props.path && Router.buildUrlFor(props.path)) || props.url;

  /**************************************************************************************************/

  const px = {};

  px.href = url;

  if (openInNewWindow) {
    px.target = '_blank';
  }

  const classNames = cn(['navbar-item', 'tw-text-base', 'tw-select-none']);

  /**************************************************************************************************/

  return (
    <a
      className={classNames}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
      {...px}
    >
      {iconProps && (
        <span className="icon !tw-mr-0">
          <Icon {...iconProps} />
        </span>
      )}

      {text}
    </a>
  );
}
