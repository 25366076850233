import NavbarDropdownLinkItem from '@components/navbar/navbar_item/NavbarDropdownLinkItem';
import NavbarDropdownHeadingItem from '@components/navbar/navbar_item/NavbarDropdownHeadingItem';
import NavbarDropdownDividerItem from '@components/navbar/navbar_item/NavbarDropdownDividerItem';
import NavbarDropdownOperationItem from '@components/navbar/navbar_item/NavbarDropdownOperationItem';

export default function NavbarMenuDropdownItem(props) {
  const {
    item,
    setShowDropdownMenu,
  } = props;

  /**************************************************************************************************/

  switch (item.type) {
  case 'link':
    return (
      <NavbarDropdownLinkItem
        label={item.label}
        {...item}
      />
    );

  case 'operation':
    return (
      <NavbarDropdownOperationItem
        label={item.label}
        setShowDropdownMenu={setShowDropdownMenu}
        {...item}
      />
    );

  case 'heading':
    return (
      <NavbarDropdownHeadingItem
        label={item.label}
        hasDivider={item.hasDivider}
      />
    );

  case 'divider':
    return (
      <NavbarDropdownDividerItem />
    );

  default:
    return null;
  }
}
