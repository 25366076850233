import { useMemo } from 'react';
import { motion } from 'framer-motion';

import cn from '@utils/cn';
import NavbarMenuDropdownItem from '@components/navbar/navbar_menu/NavbarMenuDropdownItem';

const variants = {
  initial: {
    // Fade In
    opacity: 0.0,

    // Slide Down
    height: 0,
    overflow: 'hidden',

    // Flip In
    rotateX: '340deg',
    originY: 0,

    // Transition
    transition: {
      duration: 0.15,
      ease: [0.5, 0, 1, 0.5],
    },
  },
  animate: {
    // Fade In
    opacity: 1.0,

    // Slide Down
    height: 'auto',
    overflow: 'hidden',

    // Flip In
    rotateX: '360deg',
    originY: 0,

    // Transition
    transition: {
      duration: 0.15,
      ease: [0.5, 0, 1, 0.5],
    },
  },
  exit: {
    // Fade In
    opacity: 0.0,

    // Slide Down
    height: 'auto',
    overflow: 'hidden',

    // Flip In
    rotateX: '360deg',
    originY: 0,

    // Transition
    transition: {
      duration: 0.15,
      ease: [0.5, 0, 1, 0.5],
    },
  },
};

export default function NavbarMenuDropdown(props) {
  const {
    items,
    isAlignedRight,
    setShowDropdownMenu,
  } = props;

  const classNames = cn(['navbar-dropdown'], {
    'tw-right-0': isAlignedRight,
  });

  const NavbarMenuDropdownItems = useMemo(() => items.map((item, index) => {
    if ((item.isHidden) || (item.isAdminOnly && !window.appContext.isCurrentUserAdmin)) return null;
    if (item.isVisible !== undefined && item.isVisible !== 'true') return null;

    return (
      <NavbarMenuDropdownItem
        key={index}
        item={item}
        setShowDropdownMenu={setShowDropdownMenu}
      />
    );
  }), [
    items,
    setShowDropdownMenu,
  ]);

  /**************************************************************************************************/

  return (
    <motion.div
      className={classNames}
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      layout={true}
    >
      {NavbarMenuDropdownItems}
    </motion.div>
  );
}
